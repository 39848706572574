<template>
  <div>
    <template v-if="isHost">
      <RaffleMainSection
        :description="this.options?.description || ''"
        :imageUrl="this.options?.imageRaffle?.url || ''"
        :currentStatus="this.currentStatus || ''"
      />
      <RaffleControls :triggerButton="triggerButton" :currentStatus="this.currentStatus"/>
    </template>
    <template v-else>
      <div
        v-if="this.currentStatus === RAFFLE_STATES.COMPLETED && !isQueued && !isPreviousWinner"
        class="winner-loser"
      >
        <div v-if="this.winner" class="wl-container">
          <div class="background-winner" :style="{backgroundImage: backgroundWinner} "></div>
          <d-icon class="icon" pack="fas" icon="check"  size="is-xlarge" style="color: green" />
          <h1>{{ $t("exp_raffle.youWin") }}</h1>
          <p>{{ $t("exp_raffle.youWinDescription") }}</p>
        </div>

        <div v-else class="wl-container">
          <d-icon class="icon" pack="fas" icon="xmark" size="is-xlarge" style="color: red" />
          <h1>{{ $t("exp_raffle.noWin") }}</h1>
          <p>{{ $t("exp_raffle.noWinDescription") }}</p>
        </div>

        <button @click="$router.push('/')">
          {{ $t("system.backToMenu") }}
        </button>
      </div>
      <template v-else>
          <div v-if="isPreviousWinner">
            <RaffleMainSection
              :description="this.options?.description || ''"
              :imageUrl="this.options?.imageRaffle?.url || ''"
              :currentStatus="''"
          />
            <RafflePopup
              :status="$t('exp_raffle.winPopup')"
              :text="$t('exp_raffle.winPopupDescription')"
            />
          </div>

          <div v-else-if="isQueued">
            <RaffleMainSection
              :description="this.options?.description || ''"
              :imageUrl="this.options?.imageRaffle?.url || ''"
              :currentStatus="''"
            />
            <RafflePopup
              :status="$t('exp_raffle.queuePopup')"
              :text="$t('exp_raffle.queuePopupDescription')"
            />
          </div>
          <div v-else>
            <RaffleMainSection
              :description="this.options?.description || ''"
              :imageUrl="this.options?.imageRaffle?.url || ''"
              :currentStatus="this.currentStatus || ''"
          />
          </div>
      </template>
    </template>
  </div>
</template>

<script>
import { RAFFLE_STATES } from "../../constants";
import RafflePopup from "./RafflePopup.vue";
import RaffleControls from "./RaffleControls.vue";
import RaffleMainSection from "./RaffleMainSection.vue";
import "./RaffleController.scss";
// import axios from "axios";

export default {
  components: {
    RafflePopup,
    RaffleControls,
    RaffleMainSection,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    this.$sfxLoad(this.options.sounds)
  },
  computed: {
    isHost() {
      return this.$store.getters["user/hasAdminAuthority"];
    },
  },
  style() {
    if (this.info.image) return "image is-16by9";
    else return "";
  },

  backgroundWinnerURL() {
    return  this.options.controllerBackgroundWon ? `url(${this.options.controllerBackgroundWon.url})` : null;
  },
  data() {
    return {
      RAFFLE_STATES,
      winner: false,
      currentStatus: null,
      isPreviousWinner: null,
      isQueued: false,
      isTourLeader: null,
    };
  },
  watch: {
    async currentStatus(newStatus, oldStatus) {
      if (
        oldStatus === RAFFLE_STATES.COMPLETED &&
        newStatus === RAFFLE_STATES.WAITING_FOR_PLAYERS
      ) {
        // Si se reseteo el sorteo
        if(this.winner){
          this.isPreviousWinner = true;
        }
        this.isQueued = false;
      }
      if (!oldStatus) {
        if (
          newStatus === RAFFLE_STATES.RUNNING ||
          newStatus === RAFFLE_STATES.COMPLETED
        ) {
          //Si ingreso mientras el sorteo estaba en funcionamiento
          this.isQueued = true;
        }
      }
    },
    immediate: true,
  },
  methods: {
    triggerButton(msg) {
      console.log("trigger button", msg)
      this.$socket.client.emit("gameMessage", {
        type: msg,
      });
    },
  },

  sockets: {
    winner(currentWinner) {
      if (currentWinner.winnerId === this.$store.state.user.profile.id) {
        this.winner = true;
        this.$sfxPlay('winnermobile')
      } else {
        this.winner = false;
      }
    },
    playerIsPreviousWinner(){
      // La pantalla me avisa que ya habia ganado
      this.isPreviousWinner = true;
    },
    currentStatus(status) {
      this.currentStatus = status.currentStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.main-section {
  display: flex;
  flex-direction: column;
}
.basic-page {
  display: flex;
  flex-direction: column;

  .overflow-scroll {
    flex-grow: 1;
    height: 1px;
  }
  .title {
    border-bottom: solid 1px #e0e0e0;
  }

  img {
    object-fit: cover;
  }

  .column {
    padding: 0.5rem;
  }
  .columns:last-child {
    margin-bottom: 0;
  }
  .link-buttons {
    flex-wrap: wrap;
    background: $white-bis;
    box-shadow: 0px -6px 6px $white-bis;
  }
}
</style>
