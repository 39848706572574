<template>
  <div class="queued-winner-popup">
    <div>
      <p>
        <strong>{{ status }}</strong> {{ text }}
      </p>
      <button @click="$router.push('/')">Volver al menu</button>
    </div>
  </div>
</template>

<script>
import "./RaffleController.scss";
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
