<template>
  <section class="basic-page section p-0 container">
    <div>
      <figure class="image is-16by9">
        <img :src="this.imageUrl" />
      </figure>
      <section class="section content p-5">
        <div class="title is-5 mb-3 pb-3">{{this.checkStatus}}</div>
        <div class="text">{{this.description}}</div>
      </section>
    </div>
  </section>
</template>

<script>
import { RAFFLE_STATES } from "../../constants";

export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    currentStatus: {
      type: String,
      required: true,
    },
  },
  computed: {
    checkStatus() {
      if (this.currentStatus == RAFFLE_STATES.NOT_READY) {
        return this.$t("exp_raffle.expStatus03"); // "El sorteo se encuentra apagado";
      } else if (this.currentStatus == RAFFLE_STATES.WAITING_FOR_PLAYERS) {
        if (!this.isHost) return this.$t("exp_raffle.expStatus02"); //"Ya estas participando"; 
        else return this.$t("exp_raffle.expStatus01"); //Sorteo
      } else {
        return this.$t("exp_raffle.expStatus01"); //Sorteo
      }
    },
    isHost() {
      return this.$store.getters["user/hasAdminAuthority"];
    },
  },
};
</script>
