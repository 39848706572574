<template>
  <div class="link-buttons px-4 pb-6 columns is-mobile buttons-container">
    <div class="column is-full-mobile">
      <button
        class="button is-fullwidth btn-primary"
        @click="() => triggerAction('onTrigger')"
        :disabled="isTriggerBtnDisabled"
      >
      {{ $t("exp_raffle.runRaffle") }}
        </button>
    </div>

    <div class="column is-half-mobile">
      <button
        class="button is-fullwidth btn-secondary"
        @click="() => triggerAction('onRaffleReset')"
        :disabled="isResetBtnDisabled"
      >
      {{ $t("exp_raffle.resetRaffle") }}
      </button>
    </div>

    <div v-if="!showEnableBtn" class="column is-half-mobile">
      <button
        class="button is-fullwidth btn-danger"
        @click="confirmDisable"
        :disabled="isDisableBtnDisabled"
      >
      {{ $t("exp_raffle.disableRaffle") }}
      </button>
    </div>
    

    <div v-if="showEnableBtn" class="column is-half-mobile">
      <button
        class="button is-fullwidth is-success"
        @click="() => triggerAction('onRaffleEnabled')"
      >
      <!-- {{ $t("exp_raffle.disableRaffle") }} -->
      Enable Raffle
      </button>
    </div>

  </div>
</template>

<script>
import { RAFFLE_STATES } from "@/components/constants";
import "./RaffleController.scss";

export default {
  props: {
    triggerButton: {
      type: Function,
      required: true,
    },
  },
  methods: {
    triggerAction(action) {
      this.triggerButton(action);
    },
    confirmDisable() {
     let answer =  window.confirm(this.$t('exp_raffle.alertNotification') )
      if(answer) {
        this.triggerAction('onDisableRaffle')
      }
    }
  },
  computed: {
    isTriggerBtnDisabled() {
      return this.$parent.currentStatus !== RAFFLE_STATES.WAITING_FOR_PLAYERS
    },
    isResetBtnDisabled() {
      return this.$parent.currentStatus !== RAFFLE_STATES.COMPLETED
    },
    isDisableBtnDisabled() {
      return !(this.$parent.currentStatus === RAFFLE_STATES.COMPLETED ||  this.$parent.currentStatus === RAFFLE_STATES.WAITING_FOR_PLAYERS)
    },
    showEnableBtn() {
      return this.$parent.currentStatus == RAFFLE_STATES.NOT_READY
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.buttons-container {
  flex-flow: wrap;
}
.btn-primary {
  background: #9b67f8;
  color: #fff;
  border: 1px solid #9b67f8;
}

.btn-secondary {
  background: #fff;
  color: #9b67f8;
  border: 1px solid #9b67f8;
}
.btn-danger {
  background: #e90000;
  color: #fff;
  border: 1px solid #e90000;
}
</style>
